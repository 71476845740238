import React from 'react';
import { DateTime, Info } from 'luxon';
import { IonSelectOption } from '@ionic/react';
import { StyledMonthSelect } from './MonthPicker.styles';

interface ContainerProps {
  activeDate: DateTime;
  onChangeMonth: (newValue: number) => void;
}

const MonthPicker: React.FC<ContainerProps> = ({ activeDate, onChangeMonth }) => {
  const months = Info.months(); // metadata so we don't have to hard-code the names

  const handleSelectionChange = (newValue: string) => {
    onChangeMonth(Number(newValue));
  };

  return (
    <>
      <StyledMonthSelect
        interface="popover"
        value={String(activeDate.month)}
        onIonChange={(e) => {
          handleSelectionChange(e.detail.value);
        }}
      >
        {months.map((month, index) => (
          <IonSelectOption key={index} value={String(index + 1)}>
            {month}
          </IonSelectOption>
        ))}
      </StyledMonthSelect>
    </>
  );
};

export default MonthPicker;
