import styled from 'styled-components';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { SearchBox } from 'react-instantsearch-dom';

export const StyledActionPanelGrid = styled(Grid)`
  padding-left: 2em;
  padding-right: 2em;
  background-color: var(--hively-action-panel-background);
  padding: 5px 20px;
`;

export const StyledNewEventButton = styled(Button)`
  text-transform: none;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 0.5em;
  margin-right: 0.5em;
  background-color: var(--ion-color-primary);

  /* TODO: RT - revisit whether we want hover color to change (standard in material) */
  &:hover {
    background-color: var(--ion-color-primary);
  }
`;

/**
 * Note: this is a wrapper around some algolia stuff, thus
 * the weird ais selected names
 *
 * ais-SearchBox-reset - the reset button that appears when
 * input is non-empty, moving it to overly the input instead of
 * showing up next to it
 *
 * ais-SearchBox - the "do it" button, hide it for now, keyboard
 * return searches, plus with algolia we can get search ahead results
 * anyway
 */
export const StyledSearchBox = styled(SearchBox)`
  .ais-SearchBox-input {
    height: 30px;
    outline-color: var(--ion-color-primary);
    font-size: 1rem;
    background-color: white;
  }

  .ais-SearchBox-reset {
    position: relative;
    left: -25px;
    background-color: inherit;
  }

  .ais-SearchBox-submit {
    display: none;
  }
`;

export const StyledSearchControl = styled.div`
  margin-left: 0.5em;
  margin-right: 0.5em;
  display: flex;
  align-items: center;
  height: 100%;
`;

export const StyledGridDatePicker = styled.div`
  margin-left: 0.5em;
  margin-right: 0.5em;
  display: flex;
  align-items: center;
  height: 100%;

  .MuiInputAdornment-root {
    margin-left: 0;

    button {
      padding-left: 0;
    }
  }
`;

export const StyledDateNavigator = styled.div`
  margin-left: 0.5em;
  margin-right: 0.5em;
  display: flex;
  align-items: center;
  height: 100%;
  color: #ff00ff;
`;

export const StyledViewToggleGroup = styled(ToggleButtonGroup)`
  margin-left: 0.5em;
  margin-right: 0.5em;
  display: flex;
  align-items: center;
  height: 100%;
`;

export const StyledTodayButton = styled(Button)`
  text-transform: none;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 0.5em;
  margin-right: 0.5em;
  border-color: var(--ion-color-dark-tint);
  color: var(--ion-color-dark-tint);

  &:hover {
    border-color: var(--ion-color-primary);
    color: var(--ion-color-primary);
  }
`;

export const StyledNavigationButton = styled(IconButton)``;

export const StyledTimeFrameContainer = styled.div`
  margin-left: 0.5em;
  margin-right: 0.5em;
  display: flex;
  align-items: center;
  height: 100%;
`;

// TODO: RT - below is a nasty max-height hack to prevent child elements from growing this,
// change this to do it the proper way
export const StyledTimeFrameSelect = styled(Select)`
  max-height: 40px;
`;

export const StyledFilterButton = styled(IconButton)`
  margin-left: 0.5em;
  margin-right: 0.5em;
  display: flex;
  align-items: center;
  height: 100%;
`;
