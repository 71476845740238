import React, { useEffect } from 'react';
import 'react-calendar-timeline/lib/Timeline.css';

import { Eventcalendar, MbscEventcalendarView } from '@mobiscroll/react';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import './mobiscroll-hively.css';
import { DateViewType } from '../../constants/datetime.constant';
import { StyledTimeline } from './ScheduleTimeline.styles';
import { ActivityById_activities, GetEventsByDateRange, GetEventsByDateRange_activities } from '../../GeneratedTypes';
import { useLazyQuery } from '@apollo/client';
import { useAppContext } from '../../context/AppContext';
import LOCATIONS_BY_TENANT_ID from '../../graphql/queries/LocationsByTenantId';
import { LocationsByTenantID_locations } from '../../GeneratedTypes';
import { DateTime } from 'luxon';

/**
 * Preferably mobi would define and name these types for us, but they don't seem to, so we will do the dirty work
 */
type mobi_timeline_type = 'day' | 'week' | 'month' | undefined;

interface ScheduleTimelineProps {
  anchorDate: DateTime;
  viewRange: DateViewType;
  activities: GetEventsByDateRange;
  createEvent: (id: string | null, eventInfo: Partial<ActivityById_activities>) => void;
}

const ScheduleTimeline: React.FC<ScheduleTimelineProps> = ({ anchorDate, viewRange, createEvent, activities }) => {
  const { selectedTenant } = useAppContext();
  const [getAllLocations, { data: locationData }] = useLazyQuery(LOCATIONS_BY_TENANT_ID);

  /**
   * convert from our graphql data model to the data format expected by mobiscroll
   */
  const myEvents = React.useMemo(() => {
    return activities?.activities.map((activity: GetEventsByDateRange_activities) => {
      return {
        title: activity.title,
        start: DateTime.fromISO(activity.start_date_time).toISO(),
        end: DateTime.fromISO(activity.end_date_time).toISO(),
        resource: activity.location?.id,
      };
    });
  }, [activities]);

  const myResources = React.useMemo(() => {
    return locationData?.locations.map((location: LocationsByTenantID_locations) => {
      return {
        id: location.id,
        name: location.name,
        color: 'var(--ion-color-primary)',
      };
    });
  }, [locationData]);

  useEffect(() => {
    if (selectedTenant) {
      getAllLocations({
        variables: {
          tenantID: selectedTenant,
        },
      });
    }
  }, [selectedTenant]);

  const getTimelineTypeFromDateViewType = (type: DateViewType): mobi_timeline_type => {
    switch (type) {
      case DateViewType.Day:
        return 'day';
      case DateViewType.Week:
        return 'week';
      case DateViewType.Month:
        return 'month';
    }
    // all else, default to day
    return 'day';
  };

  const view: MbscEventcalendarView = {
    timeline: {
      type: getTimelineTypeFromDateViewType(viewRange),
    },
  };

  return (
    <StyledTimeline data-testid="schedule-timeline">
      <Eventcalendar
        clickToCreate={true}
        dragToCreate={true}
        dragToMove={true}
        dragToResize={true}
        view={view}
        data={myEvents}
        resources={myResources}
        themeVariant="light"
        selectedDate={anchorDate.toJSDate()}
        renderHeader={() => {
          return null;
        }}
        onEventCreate={({ event }) =>
          createEvent(event?.id?.toString?.() ?? null, { start_date_time: event.start, end_date_time: event.end })
        }
        // TODO: onResize handler to edit the event start/end
      />
    </StyledTimeline>
  );
};

export default ScheduleTimeline;
