import { useState } from 'react';
import { IonContent, IonHeader, IonPage, IonButtons, IonIcon, useIonPopover, IonBackdrop } from '@ionic/react';
import { ellipsisVertical, filter } from 'ionicons/icons';
import KebabMenuPopover from '../../components/KebabMenuPopover';
import EventsContainer from '../../components/EventsContainer';
import { useAppContext } from '../../context/AppContext';
import styled from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';
import FilteringSlidedown from '../../components/FilteringSlidedown';
import { DateTime } from 'luxon';
import StandardToolbar from '../../components/StandardToolbar';
import { StyledToolbarButton } from '../../components/StandardToolbar/StandardToolbar.styles';
import { useUserContext } from '../../context/UserContext';

const FilterBackrop = styled(IonBackdrop)`
  opacity: 0.32;
`;

const CalendarPage: React.FC = () => {
  const { selectedTenant, changeTenant, allowTenantSwitching } = useAppContext();
  const { logout, loginWithRedirect, user } = useAuth0();
  const [filterPanelVisible, setFilterPanelVisible] = useState(false);
  const [targetDate, setTargetDate] = useState(DateTime.now().startOf('day'));
  const { isDevelopmentUser } = useUserContext();

  const [present, dismiss] = useIonPopover(KebabMenuPopover, {
    selectedTenant,
    changeTenant,
    onHide: () => dismiss(),
    logout,
    loginWithRedirect,
    user,
    canChangeTenant: allowTenantSwitching,
  });

  // TODO RT: this and all the popover junk above it belong in StandardToolbar to
  // reduce duplication (see also ScheduleGridPage.tsx), but we are about to refactor
  // all of that to use material instead of Ionic Framework, so deferring until then
  const presentPopover = (e: React.MouseEvent) => {
    present({
      event: e.nativeEvent,
    });
  };

  const onCloseFilter = () => {
    setFilterPanelVisible(false);
  };

  const renderButtons = () => {
    return (
      <IonButtons slot="end">
        {isDevelopmentUser && (
          <StyledToolbarButton onClick={() => setFilterPanelVisible(true)}>
            <IonIcon slot="icon-only" icon={filter} />
          </StyledToolbarButton>
        )}
        <StyledToolbarButton onClick={presentPopover}>
          <IonIcon slot="icon-only" icon={ellipsisVertical} />
        </StyledToolbarButton>
      </IonButtons>
    );
  };

  return (
    <IonPage>
      <IonHeader>
        <StandardToolbar>{renderButtons()}</StandardToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {filterPanelVisible ? <FilterBackrop tappable={true} visible={filterPanelVisible} /> : ''}
        <FilteringSlidedown isVisible={filterPanelVisible} onClose={onCloseFilter} />
        <EventsContainer targetDate={targetDate} setTargetDate={setTargetDate} />
      </IonContent>
    </IonPage>
  );
};

export default CalendarPage;
