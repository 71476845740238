import styled from 'styled-components';

export const StyledEventInfoPage = styled.div`
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 10px;
  height: 100%;
  background-color: #ffffff;
`;

export const StyledSectionHeading = styled.div`
  text-transform: uppercase;
  font-size: 14px;
  padding-bottom: 10px;
`;

export const AddToCalendarLink = styled.a`
  cursor: pointer;
  display: inline-block;
  color: #0f66e9;
  text-decoration: underline;
  margin-bottom: 2rem;
`;
