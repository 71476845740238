import { gql } from '@apollo/client';

const LOCATION_BY_ID = gql`
  query LocationById($locationID: uuid!) {
    locations(where: { id: { _eq: $locationID } }) {
      id
      name
      address
      gps_coordinates
    }
  }
`;

export default LOCATION_BY_ID;
