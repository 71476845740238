import { IonCol } from '@ionic/react';
import { DateTime } from 'luxon';
import {
  EventTimeSpanContainer,
  StyledDateGrid,
  StyledDateHeading,
  StyledDate,
  StyledDateSpanRow,
  StyledStartDateCol,
  StyledBasicText,
} from './EventTimeAndStatus.styles';

interface EventTimeSpanSummaryProps {
  startDateTime: DateTime;
  endDateTime: DateTime;
}

const EventTimeSpanSummary: React.FC<EventTimeSpanSummaryProps> = ({ startDateTime, endDateTime }) => {
  const spansDays = !startDateTime.hasSame(endDateTime, 'day');

  const renderTimeSpan = (start: DateTime, end: DateTime) => {
    return (
      <>
        <span>{start.toLocaleString(start.minute > 0 ? DateTime.TIME_SIMPLE : { hour: 'numeric' })}</span>
        <StyledBasicText> to </StyledBasicText>
        <span>{end.toLocaleString(end.minute > 0 ? DateTime.TIME_SIMPLE : { hour: 'numeric' })}</span>
      </>
    );
  };

  return (
    <>
      <EventTimeSpanContainer>
        <StyledDateGrid>
          <StyledDateSpanRow>
            <StyledStartDateCol>
              <StyledDateHeading>{spansDays ? 'Start Date' : 'Date'}</StyledDateHeading>
              <StyledDate>{startDateTime.toLocaleString()}</StyledDate>
            </StyledStartDateCol>
            <IonCol>
              <StyledDateHeading>{spansDays ? 'End Date' : 'Time'}</StyledDateHeading>
              <StyledDate>
                {spansDays ? endDateTime.toLocaleString() : renderTimeSpan(startDateTime, endDateTime)}
              </StyledDate>
            </IonCol>
          </StyledDateSpanRow>
        </StyledDateGrid>
      </EventTimeSpanContainer>
    </>
  );
};

export default EventTimeSpanSummary;
