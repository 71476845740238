import { gql } from '@apollo/client';

const CHANGE_ACTIVITY_DATE_AND_TIME = gql`
  mutation changeActivityDateAndTime($id: uuid!, $start_date_time: timestamptz!, $end_date_time: timestamptz!) {
    update_activities_by_pk(
      pk_columns: { id: $id }
      _set: { start_date_time: $start_date_time, end_date_time: $end_date_time }
    ) {
      id
      start_date_time
      end_date_time
    }
  }
`;

export default CHANGE_ACTIVITY_DATE_AND_TIME;
