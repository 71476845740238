import { StyledIonToolbar, StyledIonTitle, StyledLogoTitle } from './StandardToolbar.styles';
import { useAppContext } from '../../context/AppContext';
import { ProductBrand } from '../../constants/brands.constant';

interface StandardToolbarProps {
  title?: string;
}

const StandardToolbar: React.FC<StandardToolbarProps> = (props) => {
  const { currentBrand } = useAppContext();
  /**
   * If a title is passed in, we will render it in text, otherwise we'll render the logo'd
   * brand bar
   * @param title
   * @returns JSX for rendered title
   */
  const renderTitle = (title: string | undefined) => {
    if (title) {
      return <StyledIonTitle>{title}</StyledIonTitle>;
    } else {
      switch (currentBrand) {
        case ProductBrand.Hively:
          return <StyledLogoTitle alt="logo" id="app_logo" src="assets/logo/hively.svg" />;
        case ProductBrand.Astra:
          return <StyledLogoTitle alt="logo" id="app_logo" src="assets/logo/astra.svg" />;
      }
    }
  };

  return (
    <StyledIonToolbar>
      {renderTitle(props.title)}
      {props.children}
    </StyledIonToolbar>
  );
};

export default StandardToolbar;
