import styled from 'styled-components';
import { IonChip, IonLabel } from '@ionic/react';

const StyledEventTagSummary = styled.div``;

const StyledEventChip = styled(IonChip)`
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
`;

interface EventTagSummaryProps {
  tags: string[];
}

const EventTagSummary: React.FC<EventTagSummaryProps> = ({ tags }) => {
  return (
    <>
      <StyledEventTagSummary>
        {tags.map((tag, index) => (
          <StyledEventChip key={index} color="secondary">
            <IonLabel>{tag}</IonLabel>
          </StyledEventChip>
        ))}
      </StyledEventTagSummary>
    </>
  );
};

export default EventTagSummary;
