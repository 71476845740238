import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { IonContent, IonPage, IonButtons, IonIcon, useIonRouter, useIonLoading } from '@ionic/react';
import { createOutline, shareOutline } from 'ionicons/icons';
import { useQuery } from '@apollo/client';
// import EventContactSummary from '../../components/EventInfo/EventContactSummary';
import EventLocationSummary from '../../components/EventInfo/EventLocationSummary';
import EventTimeSpanSummary from '../../components/EventInfo/EventTimeSpanSummary';
import EventTagSummary from '../../components/EventInfo/EventTagSummary';
import EventDesciption from '../../components/EventInfo/EventDescription';
import { DateTime } from 'luxon';
import { AddToCalendarLink, StyledEventInfoPage, StyledSectionHeading } from './EventInfoPage.styles';
import { ActivityStatus } from '../../utils/ActivityStatusEnum';
import ACTIVITY_BY_ID from '../../graphql/queries/ActivityById';
import { useUserContext } from '../../context/UserContext';
import EventInfoHeader from '../../components/EventInfo/EventInfoHeader';
import { createCalendarLink } from '../../utils/createCalendarLink';
import CalendarLinksPopover from '../../components/CalendarLinksPopover';
import { ActivityByIdVariables } from '../../GeneratedTypes';
import { StyledToolbarButton } from '../../components/StandardToolbar/StandardToolbar.styles';

type EventParams = {
  eventId: string;
};

export interface Activity {
  id: string;
  tenant_id: string;
  start_date_time: string;
  end_date_time: string;
  title: string;
  description: string;
  location: {
    id: string;
    name: string;
  };
  tags?: string[];
  status: ActivityStatus;
}

interface ActivityData {
  activities: Activity[];
}

const EventInfoPage: React.FC = () => {
  const router = useIonRouter();
  const [present, dismiss] = useIonLoading();

  const { isLoggedIn } = useUserContext();
  const { eventId } = useParams<EventParams>();
  const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined as unknown as Event });
  const { loading, data } = useQuery<ActivityData, ActivityByIdVariables>(ACTIVITY_BY_ID, {
    variables: {
      activityID: eventId,
    },
  });

  useEffect(() => {
    loading ? present() : dismiss();
  }, [loading]);

  const onPopoverShow = (e: Event) => {
    setShowPopover({ showPopover: true, event: e });
  };

  const renderEventInfo = (event: Activity) => {
    return (
      <>
        <CalendarLinksPopover
          event={event}
          popoverState={popoverState}
          setShowPopover={setShowPopover}
          calendarLinks={createCalendarLink(event)}
        />
        <IonPage>
          <EventInfoHeader
            event={event}
            actionButtons={
              <IonButtons>
                {isLoggedIn && (
                  <StyledToolbarButton onClick={() => router.push(`${window.location.pathname}/edit`)}>
                    <IonIcon slot="icon-only" icon={createOutline} />
                  </StyledToolbarButton>
                )}
                <StyledToolbarButton>
                  <IonIcon slot="icon-only" icon={shareOutline} data-testid="share-icon" />
                </StyledToolbarButton>
              </IonButtons>
            }
          />
          <IonContent fullscreen>
            <StyledEventInfoPage>
              <EventTimeSpanSummary
                startDateTime={DateTime.fromISO(event.start_date_time)}
                endDateTime={DateTime.fromISO(event.end_date_time)}
              />
              {/* eslint-disable @typescript-eslint/no-explicit-any */}
              <AddToCalendarLink onClick={(e: any) => onPopoverShow(e)}>Add to Calendar</AddToCalendarLink>
              <StyledSectionHeading>Description</StyledSectionHeading>
              <EventDesciption description={event.description} />
              <EventTagSummary tags={event.tags ?? []} />
              <EventLocationSummary locationName={event.location?.name} locationId={event.location?.id} />
              {/* <EventContactSummary></EventContactSummary> */}
            </StyledEventInfoPage>
          </IonContent>
        </IonPage>
      </>
    );
  };

  const renderEventNotFound = () => {
    // TODO: RT - this is a good place to figure out a 404 page
    return <div>failed to find event</div>;
  };

  if (data?.activities[0]) {
    return renderEventInfo(data.activities[0]);
  } else if (!loading && !data) {
    return renderEventNotFound();
  } else {
    return <div></div>;
  }
};

export default EventInfoPage;
