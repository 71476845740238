import { setupConfig, getPlatforms } from '@ionic/react';
import BrandedApp from './BrandedApp';
import { AppProvider } from './context/AppContext';
import { UserProvider } from './context/UserContext';
import { TenantNameBlocklist } from './constants/tenants.constant';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

const App: React.FC = () => {
  const platforms = getPlatforms();
  const isDesktop = Boolean(platforms.find((p) => p === 'desktop'));

  /**
   * We allow tenant targetting by specifying a unique tenant identifier as
   * the subdomain in the URL.  For example, dundermifflin.gethively.com should
   * automatically target the tenant with the 'dundermifflin' identifier.
   * NOTE: when running on localhost or if there is no subdomain specified,
   * we will return an empty string.  There is an environment variable for emulating
   * a subdomain even when running on localhost.
   * @returns a string representation of the subdomain specified in the URL
   */
  const determineTargetedTenant = () => {
    const hostParts = (process.env.REACT_APP_PSEUDO_HOST || window.location.host).split('.');
    let targetedTenant = '';
    if (hostParts.length >= 3) {
      if (false == TenantNameBlocklist.includes(hostParts[0].toLowerCase())) {
        targetedTenant = hostParts[0];
      }
    }
    console.log(`*** initializing the requested tenant: ${targetedTenant || '[none]'}`);
    return targetedTenant;
  };

  // override device specific UX and force material design ('md') for all platforms
  setupConfig({
    mode: 'md',
  });

  return (
    <AppProvider>
      <UserProvider>
        <BrandedApp isDesktop={isDesktop} targetedTenant={determineTargetedTenant()} />
      </UserProvider>
    </AppProvider>
  );
};

export default App;
