import { useEffect } from 'react';
import styled from 'styled-components';
import { StaticGoogleMap, Marker } from 'react-static-google-map';
import { useQuery } from '@apollo/client';
import { useIonLoading } from '@ionic/react';
import LOCATION_BY_ID from '../../graphql/queries/LocationById';
import { LocationById, LocationByIdVariables } from '../../GeneratedTypes';

const StyledMapContainer = styled.div`
  min-width: 100%;
  height: 50vw;
  display: inline-block;
  margin: 15px auto;
`;

const StyledMapImage = styled.img`
  min-width: 100%;
`;

export interface Location {
  id: string;
  gps_coordinates: string;
  address: string;
  name: string;
}

interface EventMapProps {
  locationId: string;
  width?: number;
  height?: number;
}

const EventMap: React.FC<EventMapProps> = ({ width = 800, height = 400, locationId }) => {
  const [present, dismiss] = useIonLoading();
  const { loading, data } = useQuery<LocationById, LocationByIdVariables>(LOCATION_BY_ID, {
    variables: {
      locationID: locationId,
    },
  });
  const zoom = 18;
  const center = data?.locations[0].gps_coordinates ?? '39.19638761835794, -93.86786572368283';

  useEffect(() => {
    loading ? present() : dismiss();
  }, [loading]);

  return (
    <>
      <StyledMapContainer>
        <StaticGoogleMap
          size={`${width}x${height}`}
          as={(props: unknown) => <StyledMapImage {...props} style={{ minWidth: '100%' }} />}
          apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY ?? ''}
          zoom={zoom.toString()}
        >
          <Marker location={center} />
        </StaticGoogleMap>
      </StyledMapContainer>
    </>
  );
};

export default EventMap;
