import { useState, useEffect } from 'react';
import { Button, Grid, TextField } from '@mui/material';
import CHANGE_ACTIVITY_DESCRIPTION from '../../graphql/mutations/changeActivityDescription';
import { useMutation } from '@apollo/client';
import { useIonLoading } from '@ionic/react';

interface EventDescriptionEditProps {
  activityId: string;
  initialValue: string | null;
}

const EventDescriptionEdit: React.FC<EventDescriptionEditProps> = ({ initialValue, activityId }) => {
  const [description, setDescription] = useState(initialValue);
  const [changeDescription, { loading }] = useMutation(CHANGE_ACTIVITY_DESCRIPTION, {
    ignoreResults: true,
  });
  const [present, dismiss] = useIonLoading();

  useEffect(() => {
    loading ? present() : dismiss();
  }, [loading]);

  return (
    <>
      <TextField
        id="description-edit-field"
        variant="filled"
        label="Description"
        placeholder="Description..."
        multiline
        minRows="2"
        fullWidth
        value={description}
        onChange={(event) => {
          setDescription(event?.target.value);
        }}
        sx={{
          marginTop: '1.5rem;',
        }}
      />
      {initialValue !== description && (
        <Grid container justifyContent="flex-end" marginY="0.5rem">
          <Button
            size="small"
            variant="text"
            onClick={() => {
              setDescription(initialValue);
            }}
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              changeDescription({ variables: { id: activityId, description: description } });
            }}
          >
            Save
          </Button>
        </Grid>
      )}
    </>
  );
};

export default EventDescriptionEdit;
