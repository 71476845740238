import { gql } from '@apollo/client';

const TENANT_FOR_SUBDOMAIN = gql`
  query TenantBySubdomain($identifier: String!) {
    tenants(where: { identifier: { _eq: $identifier } }) {
      id
    }
  }
`;

export default TENANT_FOR_SUBDOMAIN;
