import { createTheme } from '@mui/material/styles';

export const hivelyTheme = createTheme({
  typography: {
    button: {
      textTransform: 'none',
    },
  },
});

// TODO: RT - these themes should actually extend a base theme and/or implement a theme 'interface'
// for now, astra just matches hively
export const astraTheme = hivelyTheme;
