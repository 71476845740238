import React, { useEffect } from 'react';
import { IonText, IonProgressBar, IonRow } from '@ionic/react';
import DatePicker from '../DatePicker';
import EventItem from '../EventItem';
import { DateTime } from 'luxon';
import { ApolloError, useQuery } from '@apollo/client';
import { ActivityStatus } from '../../utils/ActivityStatusEnum';
import ACTIVITIES_BY_DATE from '../../graphql/queries/ActivitiesByDate';
import CURRENT_USER from '../../graphql/queries/CurrentUser';
import ACTIVITIES_FOR_USER from '../../graphql/queries/ActivitiesForUser';
import {
  CalendarIcon,
  StyledEventContainer,
  StyledIonList,
  StyledZeroState,
  StyledUnpaddedColumn,
  StyledUnpaddedGrid,
} from './EventsContainer.styles';
import { calendar } from 'ionicons/icons';
import useStickyState from '../../hooks/StickyState';
import {
  ActivitiesForUser,
  ActivitiesForUserVariables,
  CurrentUser,
  CurrentUserVariables,
  GetEventsByDateRange,
  GetEventsByDateRangeVariables,
  GetEventsByDateRange_activities,
} from '../../GeneratedTypes';
import { useAppContext } from '../../context/AppContext';
import { useAuth0 } from '@auth0/auth0-react';

interface EventItemsProps {
  filterToUserEvents: boolean;
  userEvents?: string[];
  data?: GetEventsByDateRange;
  loading: boolean;
  error?: ApolloError;
}

export function EventItems({ data, error, userEvents, filterToUserEvents }: EventItemsProps): JSX.Element {
  if (error) return <p>Error :(</p>;

  const getTimeSpan = (event: GetEventsByDateRange_activities) => {
    const start = DateTime.fromISO(event.start_date_time);
    const end = DateTime.fromISO(event.end_date_time);
    const spanText =
      start.toFormat('h ') +
      (start.hour > 12 ? 'PM' : 'AM') +
      ' - ' +
      end.toFormat('h ') +
      (end.hour > 12 ? 'PM' : 'AM');
    return spanText;
  };

  return (
    <>
      {data &&
        data?.activities
          .filter((act) => (filterToUserEvents ? userEvents?.includes(act.id) : true))
          .map((event: GetEventsByDateRange_activities, index: number) => {
            return (
              <EventItem
                key={index}
                eventId={event.id}
                title={event.title}
                location={event.location?.name ?? 'TBD'}
                status={(event.status ?? ActivityStatus.SCHEDULED) as ActivityStatus}
                timeSpan={getTimeSpan(event)}
                // tags={event.tags}
                tags={[]}
              />
            );
          })}
    </>
  );
}

interface EventsContainerProps {
  targetDate: DateTime;
  setTargetDate: React.Dispatch<React.SetStateAction<DateTime>>;
}

export const EventsContainer: React.FC<EventsContainerProps> = ({ targetDate, setTargetDate }) => {
  const { user } = useAuth0();
  const { selectedTenant } = useAppContext();
  const { filterToMyEvents } = useAppContext();

  const [persistedDate, setPersistedDate] = useStickyState(new Date().toISOString(), 'selected-date');

  const { loading, error, data } = useQuery<GetEventsByDateRange, GetEventsByDateRangeVariables>(ACTIVITIES_BY_DATE, {
    variables: {
      startDate: targetDate.startOf('day').toString(),
      endDate: targetDate.endOf('day').toString(),
      tenantId: selectedTenant,
    },
  });

  const { data: currentUser } = useQuery<CurrentUser, CurrentUserVariables>(CURRENT_USER, {
    variables: { email: user?.email ?? '' },
  });
  const { data: userActivities } = useQuery<ActivitiesForUser, ActivitiesForUserVariables>(ACTIVITIES_FOR_USER, {
    variables: { userID: currentUser?.users?.[0]?.id },
  });

  const userEvents = userActivities?.user_activity_affiliations?.map((ua: { activity_id: string }) => ua.activity_id);

  /**
   * It's tricky to persist a DateTime value, so we have a string based date that we persist to local
   * storage an then upon initialization (i.e. when useEffect is called with an empty [], we will
   * translate over to DateTime)
   */
  useEffect(() => {
    setTargetDate(DateTime.fromISO(persistedDate));
  }, []);

  /**
   * per above, we can't easily persist a DateTime object so on a date change we need to both update
   * the working state (targetDate) and the persisted state (persistedDate)
   */
  const handleSetDateTime = (target: DateTime) => {
    setTargetDate(target);
    setPersistedDate(target.toISO().toString());
  };

  return (
    <StyledEventContainer>
      {loading && <IonProgressBar type="indeterminate" data-testid="progressbar"></IonProgressBar>}
      <StyledUnpaddedGrid>
        <IonRow>
          <StyledUnpaddedColumn size="12" size-sm="6">
            <DatePicker selectedDate={targetDate} selectDate={handleSetDateTime} />
          </StyledUnpaddedColumn>
          <StyledUnpaddedColumn size="12" size-sm="6">
            {data?.activities.length === 0 ? (
              <StyledZeroState>
                <IonText>
                  <CalendarIcon icon={calendar} /> <p>You have no events scheduled today</p>
                </IonText>
              </StyledZeroState>
            ) : (
              <StyledIonList>
                <EventItems
                  userEvents={userEvents}
                  filterToUserEvents={filterToMyEvents}
                  data={data}
                  loading={loading}
                  error={error}
                />
              </StyledIonList>
            )}
          </StyledUnpaddedColumn>
        </IonRow>
      </StyledUnpaddedGrid>
    </StyledEventContainer>
  );
};

export default EventsContainer;
