import { IonItem, IonList, IonPopover } from '@ionic/react';
import styled from 'styled-components';
import { Components } from '@ionic/core/dist/types/components';
import { ReactChild } from 'react';

export const StyledIonPopover = styled(IonPopover)``;
export const StyledIonList = styled(IonList)`
  --ion-item-background: #fff;
`;

export const _StyledIonItem = styled(IonItem)`
  --background: #fff;

  &::part(native) {
    background-color: #fff;
  }
`;

type StyledIonItemProps = Partial<Pick<Components.IonItem, 'href' | 'download' | 'lines'>> & {
  children: ReactChild | ReactChild[];
};

export const StyledIonItem = ({ href, download, lines, children }: StyledIonItemProps): JSX.Element => {
  return (
    <_StyledIonItem button target="_blank" rel="noopener noreferrer" href={href} download={download} lines={lines}>
      {children}
    </_StyledIonItem>
  );
};
