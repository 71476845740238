import { useState, useEffect } from 'react';

type StickyStateReturn<T> = [T, React.Dispatch<React.SetStateAction<T>>];

const useStickyState = <T,>(defaultValue: T, key: string): StickyStateReturn<T> => {
  const [value, setValue] = useState<T>(() => {
    const stickyValue = window.localStorage.getItem(key);
    return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
  });
  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);
  return [value, setValue];
};

export default useStickyState;
