import { useState } from 'react';
import { useIntercom, IntercomProps } from 'react-use-intercom';

type EngagementManagement = {
  registerEngagedUser: (name?: string, userId?: string, email?: string) => void;
  registerVisitor: () => void;
  disableWidget: () => void;
};

const useEngagementManagement = (): EngagementManagement => {
  const { boot: bootIntercom, shutdown: shutdownIntercom, update: updateIntercom } = useIntercom();
  const [isBooted, setIsBooted] = useState(false);

  const activateIntercom = (props: IntercomProps) => {
    props.actionColor = 'var(--hively-header-background)';
    isBooted ? updateIntercom(props) : bootIntercom(props);
    setIsBooted(true);
  };

  const deactivateIntercom = () => {
    shutdownIntercom();
    setIsBooted(false);
  };

  const registerEngagedUser = (name?: string, userId?: string, email?: string) => {
    const props: IntercomProps = {
      name,
      email,
      userId,
    };
    activateIntercom(props);
  };

  const registerVisitor = () => {
    const props: IntercomProps = {
      name: '(Anonymous Guest)',
    };
    activateIntercom(props);
  };

  const disableWidget = () => {
    deactivateIntercom();
  };

  return { registerEngagedUser, registerVisitor, disableWidget };
};

export default useEngagementManagement;
