import { useState } from 'react';
import {
  IonModal,
  IonHeader,
  IonButtons,
  IonIcon,
  IonContent,
  IonSelect,
  IonSelectOption,
  IonItem,
  IonLabel,
  IonCheckbox,
} from '@ionic/react';
import { filter, close } from 'ionicons/icons';
import styled from 'styled-components';
import { useAppContext } from '../context/AppContext';
import StandardToolbar from './StandardToolbar';
import { StyledToolbarButton } from './StandardToolbar/StandardToolbar.styles';

const FilterModal = styled(IonModal)`
  height: auto;
  max-height: 50%;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
`;

const StyledHeader = styled(IonHeader)`
  &.header-md::after {
    background: none;
  }
`;

const StyledLabeledItem = styled.div`
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;

  --border-radius: 20px;

  ion-label {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
  }

  ion-item {
    margin-top: 10px;
    border-radius: 12px;
    font-weight: 800;

    ion-select {
      width: 100%;
    }

    ion-select::part(icon) {
      opacity: 1;
    }
  }
`;

const StyledFilterContent = styled(IonContent)`
  ::part(background) {
    background: var(--hively-header-background);
  }
`;

const StyledCheckboxContainer = styled.div`
  margin-top: 1rem;
`;

interface FilteringSlidedownProps {
  isVisible: boolean;
  onClose: () => void;
}

const FilteringSlidedown: React.FC<FilteringSlidedownProps> = ({ isVisible, onClose }) => {
  const [status, setStatus] = useState<string>('');
  const [location, setLocation] = useState<string>('');
  const { toggleFilterToMyEvents, filterToMyEvents } = useAppContext();

  return (
    <FilterModal isOpen={isVisible}>
      <StyledHeader>
        <StandardToolbar title={'Filters'}>
          <IonButtons slot="start">
            <StyledToolbarButton onClick={onClose}>
              <IonIcon slot="icon-only" icon={filter} />
            </StyledToolbarButton>
          </IonButtons>
          <IonButtons slot="end">
            <StyledToolbarButton onClick={onClose}>
              <IonIcon slot="icon-only" icon={close} />
            </StyledToolbarButton>
          </IonButtons>
        </StandardToolbar>
      </StyledHeader>
      <StyledFilterContent>
        <StyledLabeledItem>
          <IonLabel color="primary">Status</IonLabel>
          <IonItem>
            <IonSelect value={status} placeholder="" onIonChange={(e) => setStatus(e.detail.value)}>
              <IonSelectOption value="scheduled">Scheduled</IonSelectOption>
              <IonSelectOption value="canceled">Canceled</IonSelectOption>
            </IonSelect>
          </IonItem>
        </StyledLabeledItem>
        <StyledLabeledItem>
          <IonLabel color="primary">Location</IonLabel>
          <IonItem>
            <IonSelect value={location} placeholder="" onIonChange={(e) => setLocation(e.detail.value)}>
              <IonSelectOption value="field 1">Field 1</IonSelectOption>
              <IonSelectOption value="field 2">Field 2</IonSelectOption>
            </IonSelect>
          </IonItem>
        </StyledLabeledItem>
        <StyledLabeledItem>
          <IonLabel color="primary">My Events</IonLabel>
          <StyledCheckboxContainer>
            <IonCheckbox checked={filterToMyEvents} onIonChange={() => toggleFilterToMyEvents()} />
          </StyledCheckboxContainer>
        </StyledLabeledItem>
      </StyledFilterContent>
    </FilterModal>
  );
};

export default FilteringSlidedown;
