import { gql } from '@apollo/client';

const UPDATE_ACTIVITY_LOCATION = gql`
  mutation UpdateActivityLocation($activityID: uuid!, $locationID: uuid!) {
    update_activities_by_pk(pk_columns: { id: $activityID }, _set: { location_id: $locationID }) {
      id
      location {
        id
      }
    }
  }
`;

export default UPDATE_ACTIVITY_LOCATION;
