import React from 'react';
import { DateTime } from 'luxon';
import {
  StyledNewEventButton,
  StyledTodayButton,
  StyledNavigationButton,
  StyledTimeFrameSelect,
  StyledActionPanelGrid,
  StyledSearchControl,
  StyledGridDatePicker,
  StyledDateNavigator,
  StyledViewToggleGroup,
  StyledTimeFrameContainer,
  StyledFilterButton,
  StyledSearchBox,
} from './ScheduleGridActionPanel.styles';
import NewDatePicker from '../NewDatePicker';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Grid from '@mui/material/Grid';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch } from 'react-instantsearch-dom';
import MenuItem from '@mui/material/MenuItem';
import { SelectChangeEvent } from '@mui/material/Select';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ShortTextIcon from '@mui/icons-material/ShortText';
import ToggleButton from '@mui/material/ToggleButton';
import { DateViewType, ScheduleViewMode } from '../../constants/datetime.constant';

interface ScheduleGridActionPanelProps {
  selectedDate: DateTime;
  setSelectedDate: React.Dispatch<React.SetStateAction<DateTime>>;
  viewRange: DateViewType;
  setViewRange: (range: DateViewType) => void;
  viewMode: ScheduleViewMode;
  setViewMode: (mode: ScheduleViewMode) => void;
  createEvent: () => void;
}

const ScheduleGridActionPanel: React.FC<ScheduleGridActionPanelProps> = ({
  selectedDate,
  setSelectedDate,
  viewRange,
  setViewRange,
  viewMode,
  setViewMode,
  createEvent,
}) => {
  const handleSelectToday = () => {
    setSelectedDate(DateTime.now());
  };

  const getIncrementFromViewType = (viewType: DateViewType): Duration => {
    // for now, calendar mode always increments a month at a time
    if (viewMode === ScheduleViewMode.Calendar) {
      return { months: 1 };
    }

    switch (viewType) {
      case DateViewType.Day:
        return { days: 1 };
      case DateViewType.Week:
        return { weeks: 1 };
      case DateViewType.Month:
        return { months: 1 };
    }
    console.log('warning: unexpected time interval: ' + viewType);
    return { days: 0 };
  };

  const handleSelectPrevious = () => {
    setSelectedDate(selectedDate.minus(getIncrementFromViewType(viewRange)));
  };

  const handleSelectNext = () => {
    setSelectedDate(selectedDate.plus(getIncrementFromViewType(viewRange)));
  };

  return (
    <StyledActionPanelGrid container data-testid="schedule-grid-action-panel">
      <Grid item>
        <StyledNewEventButton variant="contained" onClick={createEvent}>
          Request Event
        </StyledNewEventButton>
      </Grid>
      <Grid item>
        <StyledSearchControl>
          <InstantSearch
            indexName="bestbuy"
            searchClient={algoliasearch('latency', '6be0576ff61c053d5f9a3225e2a90f76')}
          >
            <StyledSearchBox
              translations={{ placeholder: 'Find event...' }}
              searchAsYouType={false}
              onChange={(event) => console.log(event)}
            />
          </InstantSearch>
        </StyledSearchControl>
      </Grid>
      <Grid item>
        <StyledGridDatePicker>
          <NewDatePicker
            selectedDate={selectedDate}
            selectDate={(val) => {
              setSelectedDate(val);
            }}
          />
        </StyledGridDatePicker>
      </Grid>
      <Grid item style={{ flex: 1, flexGrow: 1 }}>
        <StyledDateNavigator>
          <StyledTodayButton variant="outlined" onClick={handleSelectToday}>
            Today
          </StyledTodayButton>
          <StyledNavigationButton aria-label="previous day" onClick={handleSelectPrevious}>
            <ChevronLeftIcon />
          </StyledNavigationButton>
          <StyledNavigationButton aria-label="next day" onClick={handleSelectNext}>
            <ChevronRightIcon />
          </StyledNavigationButton>
        </StyledDateNavigator>
      </Grid>
      <Grid item>
        {viewMode === ScheduleViewMode.Timeline && (
          <StyledTimeFrameContainer>
            <StyledTimeFrameSelect
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={viewRange}
              onChange={(event: SelectChangeEvent<unknown>) => {
                setViewRange(event.target.value as DateViewType);
              }}
            >
              {Object.values(DateViewType).map((val, index) => {
                return (
                  <MenuItem key={index} value={val}>
                    {val}
                  </MenuItem>
                );
              })}
            </StyledTimeFrameSelect>
          </StyledTimeFrameContainer>
        )}
      </Grid>
      <Grid item>
        <StyledViewToggleGroup
          size="small"
          value={viewMode}
          exclusive
          onChange={(event: unknown, value: ScheduleViewMode) => setViewMode(value)}
          aria-label="view toggle"
        >
          <ToggleButton value={ScheduleViewMode.Timeline} aria-label="grid view">
            <ShortTextIcon />
          </ToggleButton>
          <ToggleButton value={ScheduleViewMode.Calendar} aria-label="calendar view">
            <DateRangeIcon />
          </ToggleButton>
        </StyledViewToggleGroup>
      </Grid>
      <Grid item>
        <StyledFilterButton aria-label="upload picture">
          <FilterAltIcon />
        </StyledFilterButton>
      </Grid>
    </StyledActionPanelGrid>
  );
};

export default ScheduleGridActionPanel;
