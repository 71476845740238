import { CalendarEvent, google, outlook, yahoo, ics, office365 } from 'calendar-link';
import { Activity } from '../pages/EventInfoPage/EventInfoPage';

export interface CreateCalendarLink {
  googleCalendar: string;
  outlook: string;
  iCal: string;
  yahoo: string;
  office365: string;
}

export function createCalendarLink(event: Activity): CreateCalendarLink {
  const calendarEvent: CalendarEvent = {
    title: event.title,
    description: event.description,
    start: event.start_date_time,
    end: event.end_date_time,
    location: event.location?.name,
  };

  return {
    googleCalendar: google(calendarEvent),
    outlook: outlook(calendarEvent),
    iCal: ics(calendarEvent),
    yahoo: yahoo(calendarEvent),
    office365: office365(calendarEvent),
  };
}
