import { gql } from '@apollo/client';

const ACTIVITIES_FOR_USER = gql`
  query ActivitiesForUser($userID: uuid!) {
    user_activity_affiliations(where: { user_id: { _eq: $userID } }) {
      activity_id
    }
  }
`;

export default ACTIVITIES_FOR_USER;
