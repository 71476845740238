import { Activity } from '../../pages/EventInfoPage/EventInfoPage';
import { CreateCalendarLink } from '../../utils/createCalendarLink';
import { StyledIonItem, StyledIonList, StyledIonPopover } from './CalendarLinksPopover.styles';

interface CalendarLinksPopoverProps {
  popoverState: {
    showPopover: boolean;
    event?: Event;
  };
  setShowPopover: React.Dispatch<
    React.SetStateAction<{
      showPopover: boolean;
      event: Event;
    }>
  >;
  calendarLinks: CreateCalendarLink;
  event: Activity;
}

export default function CalendarLinksPopover({
  popoverState,
  setShowPopover,
  calendarLinks,
  event,
}: CalendarLinksPopoverProps): JSX.Element {
  const onPopoverHide = () => {
    setShowPopover({ showPopover: false, event: undefined as unknown as Event });
  };

  const { googleCalendar, iCal, office365, outlook, yahoo } = calendarLinks;

  return (
    <StyledIonPopover
      event={popoverState.event}
      isOpen={popoverState.showPopover}
      onDidDismiss={onPopoverHide}
      showBackdrop={false}
      cssClass="calendar-links-popover-container"
    >
      <StyledIonList>
        <StyledIonItem href={googleCalendar}>Google Calendar</StyledIonItem>
        <StyledIonItem href={iCal} download={event.title}>
          iCal
        </StyledIonItem>
        <StyledIonItem href={outlook}>Outlook</StyledIonItem>
        <StyledIonItem href={office365}>Office365</StyledIonItem>
        <StyledIonItem lines="none" href={yahoo}>
          Yahoo
        </StyledIonItem>
      </StyledIonList>
    </StyledIonPopover>
  );
}
