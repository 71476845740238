import { IonToolbar, IonTitle, IonButton } from '@ionic/react';
import styled from 'styled-components';

export const StyledIonToolbar = styled(IonToolbar)`
  --background: var(--hively-header-background);
  border-top: 4px solid var(--hively-header-accent);
`;

export const StyledIonTitle = styled(IonTitle)`
  color: var(--ion-color-secondary-contrast);
  font-weight: 800;
`;

export const StyledLogoTitle = styled.img`
  font-weight: 800;
  height: 24px;
  margin: 30px;
`;

export const StyledToolbarButton = styled(IonButton)`
  color: var(--ion-color-secondary-contrast);
`;
