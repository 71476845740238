import { gql } from '@apollo/client';

const TENANTS = gql`
  query ActiveTenants {
    tenants(order_by: { name: asc }, where: { status: { _eq: active } }) {
      id
      name
    }
  }
`;

export default TENANTS;
