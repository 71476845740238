import styled, { css } from 'styled-components';
import { IonIcon, IonSpinner } from '@ionic/react';
import { ActivityStatus } from '../../utils/ActivityStatusEnum';

const StyledEventStatusInfo = styled.span`
  font-size: 12px;
  float: right;
`;

const getColorByStatus = (status: ActivityStatus) => {
  let color = `var(--ion-color-medium)`;

  switch (status) {
    case ActivityStatus.REQUESTED:
      color = `var(--ion-color-primary)`;
      break;
    case ActivityStatus.SCHEDULED:
      color = `var(--ion-color-success)`;
      break;
    case ActivityStatus.CANCELED:
      color = `var(--ion-color-danger)`;
      break;
  }

  return css`
    color: ${color};
  `;
};

const StyledStatusIcon = styled(IonIcon)<{ status: ActivityStatus }>`
  font-size: 20px;
  margin-right: 3px;
  vertical-align: middle;
  ${(props) => getColorByStatus(props.status)};
`;

const StyledStatusSpinner = styled(IonSpinner)`
  float: right;
  height: 20px;
`;

const StyledStatusText = styled.span`
  verticalalign: 'middle';
`;

export { StyledEventStatusInfo, StyledStatusIcon, StyledStatusText, StyledStatusSpinner };
