import { gql } from '@apollo/client';

const ADD_USER_ACTIVITY_AFFILIATIONS = gql`
  mutation AddUserActivityAffiliations($affiliations: [user_activity_affiliations_insert_input!]!) {
    insert_user_activity_affiliations(objects: $affiliations) {
      affected_rows
    }
  }
`;

export default ADD_USER_ACTIVITY_AFFILIATIONS;
