import styled from 'styled-components';

const StyledDescriptionText = styled.div`
  font-size: 12px;
  padding-bottom: 10px;
`;

interface EventDescriptionProps {
  description: string;
}

const EventDescription: React.FC<EventDescriptionProps> = ({ description }) => {
  return (
    <>
      <StyledDescriptionText>{description}</StyledDescriptionText>
    </>
  );
};

export default EventDescription;
