import { gql } from '@apollo/client';

const ACTIVITY_BY_ID = gql`
  query ActivityById($activityID: uuid!) {
    activities(where: { id: { _eq: $activityID } }) {
      id
      tenant_id
      start_date_time
      end_date_time
      title
      description
      status
      location {
        id
        name
      }
    }
  }
`;

export default ACTIVITY_BY_ID;
