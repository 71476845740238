import { gql } from '@apollo/client';

const CHANGE_ACTIVITY_DESCRIPTION = gql`
  mutation changeActivityDescription($id: uuid!, $description: String!) {
    update_activities_by_pk(pk_columns: { id: $id }, _set: { description: $description }) {
      id
      description
    }
  }
`;
export default CHANGE_ACTIVITY_DESCRIPTION;
