import { useState, useEffect } from 'react';
import { IonHeader, IonPage, IonButtons, IonIcon, useIonPopover, IonProgressBar } from '@ionic/react';
import { ellipsisVertical } from 'ionicons/icons';
import KebabMenuPopover from '../../components/KebabMenuPopover';
import { useAppContext } from '../../context/AppContext';
import { useAuth0 } from '@auth0/auth0-react';
import ScheduleTimeline from '../../components/ScheduleTimeline';
import { DateTime, Interval } from 'luxon';
import StandardToolbar from '../../components/StandardToolbar';
import { StyledToolbarButton } from '../../components/StandardToolbar/StandardToolbar.styles';
import ScheduleGridActionPanel from '../../components/ScheduleGridActionPanel';
import { DateViewType, ScheduleViewMode } from '../../constants/datetime.constant';
import ScheduleCalendar from '../../components/ScheduleCalendar';
import { StyledIonContent } from './ScheduleGridPage.styles';
import { ActivityById_activities } from '../../GeneratedTypes';
import ActivityCreationDialog from '../../components/AcitivityCreationDialog/ActivityCreationDialog';
import ACTIVITIES_BY_DATE from '../../graphql/queries/ActivitiesByDate';
import { useLazyQuery } from '@apollo/client';

const ScheduledGridPage: React.FC = () => {
  const { selectedTenant, changeTenant, allowTenantSwitching } = useAppContext();
  const { logout, loginWithRedirect, user } = useAuth0();
  const [selectedDate, setSelectedDate] = useState<DateTime>(DateTime.fromJSDate(new Date()));
  const [selectedViewRange, setSelectedViewRange] = useState<DateViewType>(DateViewType.Week);
  const [_selectedActivity, setSelectedActivity] = useState<Partial<ActivityById_activities> | null>(null);
  const [editEventModalOpen, setEditEventModalOpen] = useState<boolean>(false);
  const [viewMode, setViewMode] = useState<ScheduleViewMode>(ScheduleViewMode.Timeline);
  const [fetchRange, setFetchRange] = useState<Interval>();
  const [getActivitiesInRange, { loading, data: activitiesInRange }] = useLazyQuery(ACTIVITIES_BY_DATE);

  const handleCreateOrEditEvent = (id: string | null = null, eventInfo: Partial<ActivityById_activities> = {}) => {
    // Still plenty to clean up here - like adding the tenant ID, etc. for
    // NEW new events
    if (id?.includes?.('mbsc_')) setSelectedActivity(eventInfo ?? {});
    else setSelectedActivity({ id, ...eventInfo });
    setEditEventModalOpen(true);
  };

  // We can use the selectedActivity obj. to pre-populate the modal
  // and identify what event we're editing if it already exists (has an ID)

  const [present, dismiss] = useIonPopover(KebabMenuPopover, {
    selectedTenant,
    changeTenant,
    onHide: () => dismiss(),
    logout,
    loginWithRedirect,
    user,
    canChangeTenant: allowTenantSwitching,
  });

  // TODO RT: this and all the popover junk above it belong in StandardToolbar to
  // reduce duplication (see also CalendarPage.tsx), but we are about to refactor
  // all of that to use material instead of Ionic Framework, so deferring until then
  const presentPopover = (e: React.MouseEvent) => {
    present({
      event: e.nativeEvent,
    });
  };

  useEffect(() => {
    if (fetchRange && !fetchRange.isEmpty()) {
      getActivitiesInRange({
        variables: {
          startDate: fetchRange.start,
          endDate: fetchRange.end,
          tenantId: selectedTenant,
        },
      });
    }
  }, [fetchRange]);

  /**
   * Let's always get ~3 months of data at a time, 6 weeks on each side of the
   * selected date.  If selected Date not within known data range, re-query
   */
  useEffect(() => {
    if (selectedDate && selectedTenant) {
      if (fetchRange?.isEmpty() || !fetchRange?.contains(selectedDate)) {
        setFetchRange(
          Interval.fromDateTimes(
            selectedDate.startOf('day').minus({ weeks: 3 }),
            selectedDate.startOf('day').plus({ weeks: 3 })
          )
        );
      }
    }
  }, [selectedTenant, selectedDate]);

  const renderButtons = () => {
    return (
      <IonButtons slot="end">
        <StyledToolbarButton onClick={presentPopover}>
          <IonIcon slot="icon-only" icon={ellipsisVertical} />
        </StyledToolbarButton>
      </IonButtons>
    );
  };

  const renderContent = () => {
    if (viewMode == ScheduleViewMode.Timeline) {
      return (
        <ScheduleTimeline
          createEvent={handleCreateOrEditEvent}
          anchorDate={selectedDate}
          viewRange={selectedViewRange}
          activities={activitiesInRange}
        ></ScheduleTimeline>
      );
    } else {
      return (
        <ScheduleCalendar
          createEvent={handleCreateOrEditEvent}
          anchorDate={selectedDate}
          activities={activitiesInRange}
        ></ScheduleCalendar>
      );
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <StandardToolbar>{renderButtons()}</StandardToolbar>
      </IonHeader>
      <StyledIonContent fullscreen>
        <ScheduleGridActionPanel
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          viewRange={selectedViewRange}
          setViewRange={setSelectedViewRange}
          viewMode={viewMode}
          setViewMode={setViewMode}
          createEvent={() => handleCreateOrEditEvent()}
        ></ScheduleGridActionPanel>
        <ActivityCreationDialog isOpen={editEventModalOpen} setIsOpen={setEditEventModalOpen}></ActivityCreationDialog>
        {loading ? (
          <IonProgressBar type="indeterminate" data-testid="shedule-grid-loading"></IonProgressBar>
        ) : (
          renderContent()
        )}
      </StyledIonContent>
    </IonPage>
  );
};

export default ScheduledGridPage;
