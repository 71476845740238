import { useEffect } from 'react';

const DesktopSplashPage: React.FC = () => {
  useEffect(() => {
    window.location.href = 'https://www.gethively.com';
  }, []);

  return <></>;
};

export default DesktopSplashPage;
