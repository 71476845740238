import { User } from '@auth0/auth0-react';
import { IonList, IonItem, IonLabel, useIonToast, useIonModal } from '@ionic/react';
import TenantSwitcher from '../TenantSwitcher';

interface KebabMenuPopoverProps {
  onHide: () => void;
  user: User | undefined;
  // eslint-disable-next-line @typescript-eslint/ban-types
  logout: Function;
  // eslint-disable-next-line @typescript-eslint/ban-types
  loginWithRedirect: Function;
  canChangeTenant: boolean;
  selectedTenant: string;
  changeTenant: (tenantId: string) => void;
}

const KebabMenuPopover: React.FC<KebabMenuPopoverProps> = ({
  onHide,
  loginWithRedirect,
  logout,
  user,
  selectedTenant,
  changeTenant,
  canChangeTenant,
}) => {
  const [presentToast, dismissToast] = useIonToast();
  const [present, dismiss] = useIonModal(TenantSwitcher, {
    selectedTenant,
    onDismiss: () => {
      dismiss();
    },
    handleTenantChange: (newTenant: string): void => {
      changeTenant(newTenant);
      dismiss();
    },
  });

  const showTenantSwitcher = () => {
    present();
    onHide();
  };

  const toastNotYetImplemented = () => {
    presentToast({
      buttons: [{ text: 'hide', handler: () => dismissToast() }],
      message: 'Coming Soon!',
      duration: 2000,
    });
    onHide();
  };

  return (
    <IonList>
      {canChangeTenant && (
        <IonItem button onClick={() => showTenantSwitcher()}>
          <IonLabel>Switch Community...</IonLabel>
        </IonItem>
      )}
      <IonItem button onClick={() => toastNotYetImplemented()}>
        <IonLabel>Feedback</IonLabel>
      </IonItem>
      {user ? (
        <IonItem button onClick={() => logout({ returnTo: window.location.origin })}>
          <IonLabel>Sign Out</IonLabel>
        </IonItem>
      ) : (
        <IonItem button onClick={() => loginWithRedirect()}>
          <IonLabel>Sign In</IonLabel>
        </IonItem>
      )}
    </IonList>
  );
};

export default KebabMenuPopover;
