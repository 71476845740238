import styled from 'styled-components';

/**
 * T - this is nasty, can't remove component because it breaks date picker position, so just make it 0 size
 *  instead, and then we will use a div (see below)
 */
export const StyledDatePickerInput = styled.input`
  background: none;
  border: none;
  pointer-events: none;
  padding: 0px;
  width: 0px;
`;

/**
 * This is actually a div so that the width can be "just enough", no reliable way to do that with an input
 */
export const StyledPseudoPickerInput = styled.div`
  margin-right: 5px;
  color: var(--ion-color-dark-tint);
`;
