import { gql } from '@apollo/client';

const CHANGE_ACTIVITY_STATUS = gql`
  mutation changeActivityStatus($id: uuid!, $status: enum_activity_status_enum!) {
    update_activities_by_pk(pk_columns: { id: $id }, _set: { status: $status }) {
      id
      status
    }
  }
`;
export default CHANGE_ACTIVITY_STATUS;
