import { IonIcon } from '@ionic/react';
import styled from 'styled-components';

export const StyledLocationIcon = styled(IonIcon)`
  display: inline-block;
  vertical-align: middle;
  color: var(--ion-color-primary);
  font-size: 20px;
  margin-right: 8px;
`;

export const StyledLocationText = styled.span`
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  margin-top: 5px;
  margin-bottom: 5px;
`;
