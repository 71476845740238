import { IonBackButton, IonButtons, IonHeader } from '@ionic/react';
import { Activity } from '../../pages/EventInfoPage/EventInfoPage';
import StandardToolbar from '../StandardToolbar';
import { StyledToolbarButton } from '../StandardToolbar/StandardToolbar.styles';

export default function EventInfoHeader({
  event,
  actionButtons,
}: {
  event: Activity;
  actionButtons?: JSX.Element;
}): JSX.Element {
  return (
    <IonHeader>
      <StandardToolbar title={event.title}>
        <IonButtons slot="start">
          <StyledToolbarButton>
            <IonBackButton defaultHref="/" />
          </StyledToolbarButton>
        </IonButtons>
        {actionButtons && <IonButtons slot="end">{actionButtons}</IonButtons>}
      </StandardToolbar>
    </IonHeader>
  );
}
