import React, { useState } from 'react';
import { getPlatforms } from '@ionic/react';
import { IonGrid, IonIcon, IonRow, IonCol } from '@ionic/react';
import { DateTime, Interval } from 'luxon';
import { ellipsisHorizontal, chevronUp } from 'ionicons/icons';
import MonthPicker from '../MonthPicker';
import {
  StyledCenteredDate,
  StyledDatePicker,
  StyledDateSquare,
  StyledDateContent,
  StyledDayCol,
  StyledDayOfWeek,
  StyledDayOfWeekCol,
  StyledExpansionClickable,
  StyledPickerLabel,
} from './DatePicker.styles';

interface ContainerProps {
  selectedDate: DateTime;
  selectDate: (newDate: DateTime) => void;
}

const DatePicker: React.FC<ContainerProps> = ({ selectedDate, selectDate }) => {
  const platforms = getPlatforms();
  const isDesktop = Boolean(platforms.find((p) => p === 'desktop'));

  const [condensed, setCondensed] = useState(!isDesktop);

  const handleMonthChange = (newValue: number) => {
    const newDate = selectedDate.set({ month: newValue });
    selectDate(newDate);
  };

  const handleDateClick = (newDate: DateTime) => {
    // TODO RT: what follows is a horrendous hack.  Due to state change race condition,
    // the month picker is jacking up the date mid-stream of selectDate.  Calling it twice
    // allows time for state to get steady but is a horrible solution.  Look into whether
    // useContext would be better for other reasons anyway... and if so, hopefully it fixes
    // this.  The source of this problem may be an ionic bug/issue:
    // https://github.com/ionic-team/ionic-framework/issues/20920
    setTimeout(() => {
      if (!isDesktop) setCondensed(true);
      selectDate(newDate);
      selectDate(newDate);
    }, 100);
  };

  const renderWeek = (week: Interval) => {
    if (!condensed || week.contains(selectedDate)) {
      return (
        <IonRow key={week.start.ordinal.toString()} data-testid={`week-${week.start.weekNumber}-${week.start.year}`}>
          {week.splitBy({ days: 1 }).map((day, index) => {
            return (
              <StyledDayCol key={index}>
                <StyledDateSquare>
                  <StyledCenteredDate
                    data-testid={`day-of-week-${index}`}
                    outOfBand={day.start.month !== selectedDate.month}
                    onClick={() => {
                      handleDateClick(day.start);
                    }}
                  >
                    <StyledDateContent targeted={day.contains(selectedDate)}>
                      {day.start.toFormat('d')}
                    </StyledDateContent>
                  </StyledCenteredDate>
                </StyledDateSquare>
              </StyledDayCol>
            );
          })}
        </IonRow>
      );
    }
  };

  /**
   * NOTE: Luxon does not obey locale-specific week specifications
   * such as which day the week starts on and instead hard-coded the first
   * day of the week to be Monday.  Thus, we'll need to subtract a day
   * for the US convention of starting the week on a Sunday
   */
  const getFirstDayOfWeek = (anyDay: DateTime) => {
    return anyDay.startOf('week').minus({ days: 1 });
  };

  /**
   * NOTE: as with getFirstDayOfWeek, we need to account for week starting
   * on Sunday rather than Monday
   */
  const getLastDayOfWeek = (anyDay: DateTime) => {
    // The 7th day of the week according to Luxon (and ISO) is Sunday but
    // we actually want to treat that as the next week, so bounce the
    // date forward 1 to get into the correct week.
    if (anyDay.weekday === 7) {
      anyDay = anyDay.plus({ days: 1 });
    }
    return anyDay.endOf('week').minus({ days: 1 });
  };

  const renderWeeks = () => {
    const firstDayOfFirstWeek = getFirstDayOfWeek(selectedDate.startOf('month'));
    const lastDayofLastWeek = getLastDayOfWeek(selectedDate.endOf('month'));
    const fullInterval = Interval.fromDateTimes(firstDayOfFirstWeek, lastDayofLastWeek);
    return <>{fullInterval.splitBy({ week: 1 }).map((week) => renderWeek(week))}</>;
  };

  const renderDayHeaders = () => {
    const arbitraryWeek = Interval.after(getFirstDayOfWeek(selectedDate), { day: 7 });
    return (
      <IonRow>
        {arbitraryWeek.splitBy({ days: 1 }).map((day, index) => (
          <StyledDayOfWeekCol key={index}>
            <StyledDayOfWeek>{day.start.toLocaleString({ weekday: 'short' })}</StyledDayOfWeek>
          </StyledDayOfWeekCol>
        ))}
      </IonRow>
    );
  };

  return (
    <StyledDatePicker>
      <IonGrid>
        <IonRow>
          <StyledPickerLabel>Calendar</StyledPickerLabel>
          <IonCol className="ion-align-self-stretch">
            <MonthPicker activeDate={selectedDate} onChangeMonth={handleMonthChange}></MonthPicker>
          </IonCol>
        </IonRow>
        {renderDayHeaders()}
        {renderWeeks()}
      </IonGrid>
      <StyledExpansionClickable>
        {!isDesktop && (
          <IonIcon
            // TODO: change to use AppContext after we reintroduce that, per Ryan
            icon={condensed ? ellipsisHorizontal : chevronUp}
            role="button"
            onClick={() => setCondensed(!condensed)}
            ariaLabel={condensed ? 'expandDatePicker' : 'collapseDatePicker'}
          />
        )}
      </StyledExpansionClickable>
    </StyledDatePicker>
  );
};

export default DatePicker;
