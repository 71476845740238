import styled from 'styled-components';
import { IonGrid, IonRow, IonList, IonSelect, IonCol, IonInput, IonIcon } from '@ionic/react';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

const EventTimeSpanContainer = styled.div`
  margin-top: 10px;
  padding-bottom: 0px;
`;

const StyledDateGrid = styled(IonGrid)`
  padding-left: 0px;
  padding-right: 0px;
`;

const StyledDateSpanRow = styled(IonRow)``;

const StyledDateHeading = styled.div`
  text-transform: uppercase;
  color: #989898;
  font-weight: 700;
  font-size: 14px;
`;

const StyledDate = styled.div`
  font-weight: 700;
  font-size: 18px;
  margin-left: auto;
`;

const StyledIonList = styled(IonList)`
  width: 100%;
  max-width: 100%;
  padding: 0;
`;

const StyledIonSelect = styled(IonSelect)`
  margin-left: auto;
`;

const StyledStartDateCol = styled(IonCol)`
  max-width: 33%;
`;

const StyledBasicText = styled.span`
  font-weight: 400;
`;

const StyledIonInputContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  :first-child {
    /* margin-right: 1rem; */
  }

  > :nth-child(2),
  > :nth-child(3) {
    font-size: 2rem;
  }

  > :nth-child(2) {
    margin-left: 5px;
    color: var(--ion-color-danger);
    margin-right: 0.2rem;
  }

  > :nth-child(3) {
    color: var(--ion-color-success);
    --ionicon-stroke-width: 4rem;
    margin-bottom: 5px;
  }
`;

const StyledIonInput = styled(IonInput)`
  margin-left: 10%;
`;

const StyledAutocomplete = styled(Autocomplete)`
  width: 100%;
  padding: 8px 0;
` as typeof Autocomplete;

const StyledInput = styled(TextField)`
  width: 100%;
`;

const PencilIcon = styled(IonIcon)`
  margin-left: 0.4rem;
  font-size: 1.5rem;
`;

export {
  EventTimeSpanContainer,
  StyledDateGrid,
  StyledDateSpanRow,
  StyledDateHeading,
  StyledDate,
  StyledIonList,
  StyledIonSelect,
  StyledStartDateCol,
  StyledBasicText,
  StyledIonInputContainer,
  StyledIonInput,
  StyledInput,
  StyledAutocomplete,
  PencilIcon,
};
