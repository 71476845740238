import React, { useEffect } from 'react';
import { Eventcalendar, toast, MbscCalendarEvent, MbscEventcalendarView } from '@mobiscroll/react';
import 'react-calendar-timeline/lib/Timeline.css';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import './mobiscroll-hively.css';
import { StyledCalendarContainer } from './ScheduleCalendar.styles';
import { ActivityById_activities, GetEventsByDateRange, GetEventsByDateRange_activities } from '../../GeneratedTypes';
import { DateTime } from 'luxon';

interface ScheduleCalendarProps {
  anchorDate: DateTime;
  createEvent: (id: string | null, eventInfo: Partial<ActivityById_activities>) => void;
  activities: GetEventsByDateRange;
}

const ScheduleCalendar: React.FC<ScheduleCalendarProps> = ({ anchorDate, createEvent, activities }) => {
  const [myEvents, setEvents] = React.useState<MbscCalendarEvent[]>([]);

  useEffect(() => {
    if (activities) {
      setEvents(
        activities?.activities?.map((activity: GetEventsByDateRange_activities) => {
          return {
            start: DateTime.fromISO(activity.start_date_time).toISO(),
            end: DateTime.fromISO(activity.end_date_time).toISO(),
            title: activity.title,
            resource: activity.location?.name,
            id: activity.id,
          };
        })
      );
    }
  }, [activities]);

  const onEventClick = React.useCallback((event) => {
    toast({
      message: event.event.title,
    });
  }, []);

  const view = React.useMemo<MbscEventcalendarView>(() => {
    return {
      calendar: { labels: true },
    };
  }, []);

  return (
    <StyledCalendarContainer data-testid="schedule-calendar">
      <Eventcalendar
        clickToCreate={true}
        dragToCreate={true}
        dragToMove={true}
        dragToResize={false}
        view={view}
        data={myEvents}
        selectedDate={anchorDate.toJSDate()}
        themeVariant="light"
        onEventClick={onEventClick}
        renderHeader={() => {
          return null;
        }}
        onEventCreate={({ event }) =>
          createEvent(event?.id?.toString?.() ?? null, { start_date_time: event.start, end_date_time: event.end })
        }
        // TODO: onResize handler to edit the event start/end
      />
    </StyledCalendarContainer>
  );
};

export default ScheduleCalendar;
