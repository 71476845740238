import { IonSelect } from '@ionic/react';
import styled from 'styled-components';

export const StyledMonthPicker = styled.div`
  text-align: right;
`;

export const StyledMonthSelect = styled(IonSelect)`
  ::part(text) {
    font-weight: 700;
    text-align: right;
    padding-right: 5px;
  }

  ::part(icon) {
    background-color: var(--ion-color-primary);
    opacity: 1;
    border-radius: 25px;
    color: var(--ion-color-primary-contrast);
  }
`;
