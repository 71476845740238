import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';

const apolloClient = new ApolloClient({
  uri: process.env.REACT_APP_HASURA_API_URL,
  cache: new InMemoryCache(),
});

const HasuraProvider: React.FC = (props) => {
  return <ApolloProvider client={apolloClient}>{props.children}</ApolloProvider>;
};

export default HasuraProvider;
