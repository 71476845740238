import { gql } from '@apollo/client';

const USERS_BY_ACTIVITY = gql`
  query UsersByActivity($activityID: uuid!) {
    user_activity_affiliations(where: { activity_id: { _eq: $activityID } }) {
      user {
        first_name
        last_name
        id
      }
    }
  }
`;

export default USERS_BY_ACTIVITY;
