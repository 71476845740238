import { gql } from '@apollo/client';

const USERS_BY_TENANT = gql`
  query UsersByTenant($tenantID: uuid!) {
    tenant_users(where: { tenant_id: { _eq: $tenantID } }) {
      user {
        id
        first_name
        last_name
        email
      }
    }
  }
`;

export default USERS_BY_TENANT;
