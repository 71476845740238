import { IonCol } from '@ionic/react';
import styled, { css } from 'styled-components';

const getTargetedStyle = (targeted: boolean) => {
  if (targeted) {
    return css`
      border-radius: 50%;
      background-color: var(--ion-color-primary);
      color: var(--ion-color-primary-contrast);
    `;
  }
};

export const StyledDatePicker = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  border-radius: 0px 0px 25px 25px;
  background-color: #ffffff;
`;

export const StyledPickerLabel = styled(IonCol)`
  padding-top: 15px;
  font-size: 18px;
  font-weight: 600;
`;

export const StyledExpansionClickable = styled.div`
  text-align: center;
  color: #9e9e9e;
`;

export const StyledDayOfWeekCol = styled(IonCol)`
  padding-bottom: 0;
`;

export const StyledDayOfWeek = styled.div`
  text-align: center;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  color: #5e5e5e;
`;

export const StyledDayCol = styled(IonCol)``;
/**
 * Artificial container to force squareness in the date
 */
export const StyledDateSquare = styled.div`
  position: relative;
  width: 100%;
  padding-top: 100%;
`;

export const StyledCenteredDate = styled.div<{ outOfBand: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => {
    return props.outOfBand ? '#c3c3c3' : '#131313';
  }};
`;

export const StyledDateContent = styled.div<{ targeted: boolean }>`
  font-size: 18px;
  font-weight: 700;
  height: 35px;
  width: 35px;
  line-height: 35px;
  text-align: center;
  ${(props) => getTargetedStyle(props.targeted)}
`;
