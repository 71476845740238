import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { createContext, PropsWithChildren, useContext, useState } from 'react';
import useEngagementManagement from '../hooks/EngagementManagement';
import { useAppContext } from './AppContext';
import { ProductBrand } from '../constants/brands.constant';

export interface UserContextProps {
  isLoggedIn: boolean;
  isDevelopmentUser: boolean;
}

export const defaultUserContext = {
  isLoggedIn: false,
  isDevelopmentUser: false,
};

const UserContext = createContext<UserContextProps>(defaultUserContext);

const useUserContext = (): UserContextProps => useContext(UserContext);

const UserProvider = ({ children }: PropsWithChildren<Record<string, unknown>>): JSX.Element => {
  const [isLoggedIn, setIsLoggedIn] = useState(defaultUserContext.isLoggedIn);
  const [isDevelopmentUser, setIsDevelopmentUser] = useState(defaultUserContext.isDevelopmentUser);
  const { isAuthenticated, user } = useAuth0();
  const { registerEngagedUser, registerVisitor, disableWidget } = useEngagementManagement();
  const { selectedTenant, currentBrand } = useAppContext();

  /**
   * This might seem rendundant but the idea here is to create an abstraction so that our UI code
   * does not need to know specifically about our Auth0 (currently) implementation details
   */
  useEffect(() => {
    setIsLoggedIn(isAuthenticated);
    if (user) {
      const roles: Array<string> = user['https://gethively.com/roles'];
      setIsDevelopmentUser(roles?.some((role) => role.toLowerCase() === 'developer'));
    }
  }, [isAuthenticated]);

  /**
   * If the user log in status, the selected tenant or the brand change, we need to re-register with
   * our user engagement management service
   */
  useEffect(() => {
    if (currentBrand == ProductBrand.Astra) {
      disableWidget();
    } else {
      if (isLoggedIn) {
        registerEngagedUser(user?.name, user?.sub, user?.email);
      } else {
        registerVisitor();
      }
    }
  }, [isLoggedIn, selectedTenant, currentBrand]);

  return (
    <UserContext.Provider
      value={{
        isLoggedIn,
        isDevelopmentUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { useUserContext, UserProvider };
