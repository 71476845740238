import {
  IonPage,
  IonHeader,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonButtons,
  IonRadioGroup,
  IonRadio,
  IonListHeader,
} from '@ionic/react';
import HasuraProvider from '../../Hasura';
import { useQuery } from '@apollo/client';
import TENANTS from '../../graphql/queries/Tenants';
import { ActiveTenants, ActiveTenants_tenants } from '../../GeneratedTypes';
import StandardToolbar from '../StandardToolbar';
import { StyledToolbarButton } from '../StandardToolbar/StandardToolbar.styles';

interface TenantSwitcherProps {
  onDismiss: () => unknown;
  selectedTenant: number;
  handleTenantChange: (tenantID: string) => void;
}

export function TenantItems(): React.ReactElement {
  const { loading, error, data } = useQuery<ActiveTenants>(TENANTS);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <>
      {data?.tenants?.map(({ name, id }: ActiveTenants_tenants, idx: number) => (
        <IonItem key={idx}>
          <IonLabel>{name}</IonLabel>
          <IonRadio slot="start" value={id} />
        </IonItem>
      ))}
    </>
  );
}

const TenantSwitcherPage: React.FC<TenantSwitcherProps> = ({ onDismiss, handleTenantChange, selectedTenant }) => {
  const onSelectionChanged = (newValue: string) => {
    handleTenantChange(newValue);
  };

  return (
    <IonPage>
      <IonHeader>
        <StandardToolbar title={'Communities'}>
          <IonButtons slot="end">
            <StyledToolbarButton role="button" onClick={onDismiss}>
              Close
            </StyledToolbarButton>
          </IonButtons>
        </StandardToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonList>
          <IonRadioGroup value={selectedTenant} onIonChange={(e) => onSelectionChanged(e.detail.value)}>
            <IonListHeader>
              <IonLabel>Switch to data for another community: </IonLabel>
            </IonListHeader>
            <TenantItems />
          </IonRadioGroup>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

/**
 * We have to have this outer wrapper so can set the provider context for this components
 * This will go away when we move to MUI so minimizing refactoring on it for now.
 * @returns
 */
const TenantSwitcherWrapper: React.FC<TenantSwitcherProps> = (props) => {
  return (
    <HasuraProvider>
      <TenantSwitcherPage {...props} />
    </HasuraProvider>
  );
};

export default TenantSwitcherWrapper;
