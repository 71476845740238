export enum DateViewType {
  Day = 'day',
  Week = 'week',
  Month = 'month',
}

export enum ScheduleViewMode {
  Timeline = 'timeline',
  Calendar = 'calendar',
}
