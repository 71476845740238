import { gql } from '@apollo/client';

const CURRENT_USER = gql`
  query CurrentUser($email: String!) {
    users(where: { email: { _eq: $email } }) {
      id
    }
  }
`;

export default CURRENT_USER;
