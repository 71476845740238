import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { ProductBrand } from '../constants/brands.constant';
import useStickyState from '../hooks/StickyState';
import TENANT_FOR_SUBDOMAIN from '../graphql/queries/TenantBySubdomain';
import TENANT_DETAILS from '../graphql/queries/TenantBranding';
import { useLazyQuery } from '@apollo/client';

export interface AppContextProps {
  selectedTenant: string;
  changeTenant: (tenantId?: string) => void;
  currentBrand: ProductBrand;
  setCurrentBrand: React.Dispatch<React.SetStateAction<ProductBrand>>;
  requestTenantBySubDomain: (subDomain: string) => void;
  allowTenantSwitching: boolean;
  filterToMyEvents: boolean;
  toggleFilterToMyEvents: () => void;
}

export const defaultAppContext = {
  selectedTenant: '',
  changeTenant: undefined as unknown as AppContextProps['changeTenant'],
  currentBrand: ProductBrand.Hively,
  setCurrentBrand: undefined as unknown as AppContextProps['setCurrentBrand'],
  requestTenantBySubDomain: undefined as unknown as AppContextProps['requestTenantBySubDomain'],
  allowTenantSwitching: 'true' === process.env.REACT_APP_ALLOW_TENANT_SWITCHING,
  filterToMyEvents: false,
  toggleFilterToMyEvents: (): void => undefined,
};

const AppContext = createContext<AppContextProps>(defaultAppContext);

const useAppContext = (): AppContextProps => useContext(AppContext);

const AppProvider = ({ children }: PropsWithChildren<Record<string, unknown>>): JSX.Element => {
  const [selectedTenant, setSelectedTenant] = useState(defaultAppContext.selectedTenant);
  const [currentBrand, setCurrentBrand] = useState(defaultAppContext.currentBrand);
  const [getSubdomainTenant, { loading: subDomainLoading, data: subDomainData }] = useLazyQuery(TENANT_FOR_SUBDOMAIN);
  const [getTenantDetails, { loading: tenantDetailLoading, data: tenantDetailData }] = useLazyQuery(TENANT_DETAILS);
  const [allowTenantSwitching, setAllowTenantSwitching] = useState(defaultAppContext.allowTenantSwitching);
  const [filterToMyEvents, setFilterToMyEvents] = useStickyState(false, 'filter-my-events');

  const toggleFilterToMyEvents = () => setFilterToMyEvents(!filterToMyEvents);

  useEffect(() => {
    if (!subDomainLoading && subDomainData?.tenants?.length > 0) {
      changeTenant(subDomainData.tenants[0].id);
      setAllowTenantSwitching(false);
    }
  }, [subDomainData, subDomainLoading]);

  useEffect(() => {
    if (!tenantDetailLoading && tenantDetailData?.tenants?.length > 0) {
      const tenantInfo = tenantDetailData.tenants[0];
      setSelectedTenant(tenantInfo.id);
      if (tenantInfo.brand == 'astra') {
        setCurrentBrand(ProductBrand.Astra);
      } else {
        setCurrentBrand(ProductBrand.Hively);
      }
    }
  }, [tenantDetailData, tenantDetailLoading]);

  const requestTenantBySubDomain = (subDomain: string) => {
    if (subDomain) {
      getSubdomainTenant({ variables: { identifier: subDomain } });
    } else {
      changeTenant(process.env.REACT_APP_FALLBACK_TENANT);
    }
  };

  const changeTenant = (newTenantId?: string) => {
    if (newTenantId) {
      getTenantDetails({ variables: { tenantID: newTenantId } });
    } else {
      // TODO: RT - what to do if no tenant is configured???
      console.log('Invalid tenant requested');
    }
  };

  return (
    <AppContext.Provider
      value={{
        selectedTenant,
        changeTenant,
        currentBrand,
        setCurrentBrand,
        requestTenantBySubDomain,
        allowTenantSwitching,
        filterToMyEvents,
        toggleFilterToMyEvents,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { useAppContext, AppProvider };
