import { useMutation, useQuery } from '@apollo/client';
import { IonList, IonItem, IonText, IonButton, IonSelect, IonSelectOption, IonIcon, IonLabel } from '@ionic/react';
import { trashOutline } from 'ionicons/icons';
import styled from 'styled-components';
import React from 'react';
import USERS_BY_ACTIVITY from '../../graphql/queries/UsersByActivity';
import REMOVE_USER_ACTIVITY_AFFILIATION from '../../graphql/mutations/RemoveUserActivityAffiliation';
import USERS_BY_TENANT from '../../graphql/queries/UsersByTenant';
import { Activity } from '../../pages/EventInfoPage/EventInfoPage';
import { SelectChangeEventDetail } from '@ionic/core';
import ADD_USER_ACTIVITY_AFFILIATIONS from '../../graphql/mutations/AddUserActivityAffiliations';
import {
  AddUserActivityAffiliations,
  AddUserActivityAffiliationsVariables,
  UsersByActivity,
  UsersByActivityVariables,
  UsersByTenant,
  UsersByTenantVariables,
} from '../../GeneratedTypes';

const StyledSectionLabel = styled.div`
  text-transform: uppercase;
  font-size: 14px;
  padding: 1rem 1rem 0;
  margin-top: 1.5rem;
`;

const StyledList = styled(IonList)`
  background-color: #ffffff;
  padding-top: 0px;
  padding-bottom: 10px;
`;

const StyledListItem = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`;

const UserSelect = ({
  users,
  onChange,
  value,
}: {
  users: { first_name: string; last_name: string; id: string }[];
  onChange: (event: CustomEvent<SelectChangeEventDetail<string[]>>) => void;
  value: string[];
}) => {
  return (
    <IonSelect onIonChange={onChange} value={value} multiple placeholder="Add users to activity">
      <IonLabel>Users</IonLabel>
      {users.map((user) => (
        <IonSelectOption key={user.id} value={user.id}>
          {user.first_name}
          {user.last_name && ` ${user.last_name}`}
        </IonSelectOption>
      ))}
    </IonSelect>
  );
};

// eslint-disable-next-line
const UserActivityAffiliationList = ({ activity }: { activity: Activity }): React.ReactElement => {
  const { data: tenantUsers } = useQuery<UsersByTenant, UsersByTenantVariables>(USERS_BY_TENANT, {
    variables: { tenantID: activity.tenant_id },
  });

  const { data: usersAffiliated, refetch } = useQuery<UsersByActivity, UsersByActivityVariables>(USERS_BY_ACTIVITY, {
    variables: { activityID: activity.id },
  });

  const [removeAffiliation] = useMutation(REMOVE_USER_ACTIVITY_AFFILIATION);
  const [mutateAddAffiliations] = useMutation<AddUserActivityAffiliations, AddUserActivityAffiliationsVariables>(
    ADD_USER_ACTIVITY_AFFILIATIONS
  );
  const [stagedUsersToAdd, setStagedUsers] = React.useState<string[]>([]);

  const buildAddAffiliationsHandler = (userIDs: string[]) => async () => {
    await mutateAddAffiliations({
      variables: {
        affiliations: userIDs.map((id) => ({
          user_id: id,
          activity_id: activity.id,
        })),
      },
    });
    setStagedUsers([]);
    await refetch();
  };

  const buildRemoveHandler = (userID: string, activityID: string) => async () => {
    await removeAffiliation({ variables: { userID, activityID } });
    await refetch();
  };

  return (
    <StyledList>
      <StyledSectionLabel>Users Affiliated</StyledSectionLabel>
      {!usersAffiliated?.user_activity_affiliations?.length && <IonItem>No users affiliated yet.</IonItem>}
      {usersAffiliated?.user_activity_affiliations?.map(({ user }) => (
        <IonItem>
          <StyledListItem>
            <IonText>
              {user.first_name}
              {user.last_name && ` ${user.last_name}`}
            </IonText>
            <IonButton onClick={buildRemoveHandler(user.id, activity.id)}>
              <IonIcon icon={trashOutline} />
            </IonButton>
          </StyledListItem>
        </IonItem>
      ))}
      <IonItem>
        <StyledListItem>
          <span style={{ width: '100%' }}>
            <UserSelect
              users={
                tenantUsers?.tenant_users?.map(
                  (item: { user: { id: string; first_name: string; last_name: string; email: string } }) => item.user
                ) ?? []
              }
              value={stagedUsersToAdd}
              onChange={(e) => {
                setStagedUsers(e.detail.value);
              }}
            />
          </span>
          <IonButton onClick={buildAddAffiliationsHandler(stagedUsersToAdd)}>Add</IonButton>
        </StyledListItem>
      </IonItem>
    </StyledList>
  );
};

export default UserActivityAffiliationList;
