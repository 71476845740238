import styled from 'styled-components';
import LocationSnippet from '../LocationSnippet';
import EventMap from './EventMap';

const StyledEventLocationSummary = styled.div``;

const StyledSectionHeadling = styled.div`
  padding-top: 20px;
  text-transform: uppercase;
  font-size: 14px;
  padding-bottom: 10px;
`;

const StyledNothingText = styled.div`
  font-size: 12px;
  font-weight: 400;
`;

interface EventLocationSummaryProps {
  locationName: string;
  locationId: string;
}

const EventLocationSummary: React.FC<EventLocationSummaryProps> = ({ locationName, locationId }) => {
  const renderLocationName = (name: string) => {
    if (name) {
      return <LocationSnippet location={locationName}></LocationSnippet>;
    } else {
      return <StyledNothingText>(location not specified)</StyledNothingText>;
    }
  };

  const renderLocationMap = (locationId: string) => {
    if (locationId) {
      return <EventMap locationId={locationId} />;
    } else {
      return <StyledNothingText>(map not provided)</StyledNothingText>;
    }
  };

  return (
    <>
      <StyledEventLocationSummary>
        <StyledSectionHeadling>Location</StyledSectionHeadling>
        {renderLocationName(locationName)}
        {renderLocationMap(locationId)}
      </StyledEventLocationSummary>
    </>
  );
};

export default EventLocationSummary;
