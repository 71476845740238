import { navigate } from 'ionicons/icons';
import { StyledLocationIcon, StyledLocationText } from './LocationSnippet.styles';

interface LocationSnippetProps {
  location: string;
}

const LocationSnippet: React.FC<LocationSnippetProps> = ({ location }) => {
  return (
    <div>
      <StyledLocationIcon icon={navigate} />
      <StyledLocationText>{location}</StyledLocationText>
    </div>
  );
};

export default LocationSnippet;
