import { IonContent, IonList, IonIcon, IonCol, IonGrid } from '@ionic/react';
import styled from 'styled-components';

export const StyledEventContainer = styled(IonContent)``;
export const StyledIonList = styled(IonList)``;
export const CalendarIcon = styled(IonIcon)`
  font-size: 4rem;
  margin-bottom: 1rem;
`;

export const StyledUnpaddedColumn = styled(IonCol)`
  padding: 0;
`;

export const StyledUnpaddedGrid = styled(IonGrid)`
  padding: 0;
`;

export const StyledZeroState = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-top: 1rem;
  margin: 3rem auto;
  width: 80%;
  color: #a7a7a7;

  p {
    font-size: 1.2rem;
    font-weight: 700;
    margin: 0 20%;
    line-height: 1.3;
  }
`;
