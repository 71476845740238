import { gql } from '@apollo/client';

const LOCATIONS_BY_TENANT_ID = gql`
  query LocationsByTenantID($tenantID: uuid!) {
    locations(where: { tenant_id: { _eq: $tenantID } }, order_by: { name: asc }) {
      id
      name
      short_name
      tenant_id
      gps_coordinates
      created_at
      address
      updated_at
    }
  }
`;

export default LOCATIONS_BY_TENANT_ID;
