import { gql } from '@apollo/client';

const REMOVE_USER_ACTIVITY_AFFILIATION = gql`
  mutation RemoveUserActivityAffiliation($userID: uuid!, $activityID: uuid!) {
    delete_user_activity_affiliations_by_pk(user_id: $userID, activity_id: $activityID) {
      user_id
      activity_id
    }
  }
`;

export default REMOVE_USER_ACTIVITY_AFFILIATION;
