import React from 'react';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { DateTime } from 'luxon';
import { Box } from '@mui/system';

interface EventDatePickerProps {
  initialDate: DateTime;
  handleChange: (newDateTime: DateTime) => void;
}

const EventDatePicker: React.FC<EventDatePickerProps> = ({ initialDate, handleChange }) => {
  const [value, setValue] = React.useState<DateTime | null>(initialDate);

  // TODO RT: we had to do some weird things to get this renderInput to play nice with
  // ionic, after removal of ionic see if we can revert to "out of the box" renderInput
  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <DatePicker
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        onAccept={(newValue) => {
          if (newValue) handleChange(newValue);
        }}
        renderInput={({ inputRef, inputProps, InputProps }) => {
          inputProps = { ...inputProps };
          inputProps.style = {
            textAlign: 'right',
            borderStyle: 'none',
            ...inputProps.style,
          };
          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <input ref={inputRef} {...inputProps} />
              {InputProps?.endAdornment}
            </Box>
          );
        }}
      />
    </LocalizationProvider>
  );
};

export default EventDatePicker;
