import { gql } from '@apollo/client';

const TENANT_DETAILS = gql`
  query TenantBranding($tenantID: uuid!) {
    tenants(where: { id: { _eq: $tenantID } }) {
      id
      brand
    }
  }
`;

export default TENANT_DETAILS;
