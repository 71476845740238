import { gql } from '@apollo/client';

const ACTIVITIES_BY_DATE = gql`
  query GetEventsByDateRange($startDate: timestamptz!, $endDate: timestamptz!, $tenantId: uuid!) {
    activities(
      where: { start_date_time: { _gte: $startDate, _lt: $endDate }, tenant_id: { _eq: $tenantId } }
      order_by: { start_date_time: asc }
    ) {
      id
      start_date_time
      end_date_time
      title
      description
      location {
        id
        name
      }
      status
      # tags - doesn't yet appear on this obj
    }
  }
`;

export default ACTIVITIES_BY_DATE;
