import { checkmarkCircle, closeCircle, helpCircle } from 'ionicons/icons';
import { MouseEvent } from 'react';
import { ActivityStatus } from '../../utils/ActivityStatusEnum';
import {
  StyledEventStatusInfo,
  StyledStatusIcon,
  StyledStatusText,
  StyledStatusSpinner,
} from './EventStatusInfo.styles';
import { useUserContext } from '../../context/UserContext';

export interface EventStatusIndicatorProps {
  status: ActivityStatus;
  updating: boolean;
  onChangeStatus: (e: MouseEvent) => void;
}

const EventStatusInfo: React.FC<EventStatusIndicatorProps> = ({ status, updating, onChangeStatus }) => {
  const { isLoggedIn } = useUserContext();

  const handleClick = (e: MouseEvent) => {
    if (isLoggedIn) {
      e.preventDefault();
      onChangeStatus(e);
    }
  };

  const renderIcon = (status: ActivityStatus) => {
    switch (status) {
      case ActivityStatus.REQUESTED:
        return <StyledStatusIcon status={status} icon={helpCircle} />;
      case ActivityStatus.SCHEDULED:
        return <StyledStatusIcon status={status} icon={checkmarkCircle} />;
      case ActivityStatus.CANCELED:
        return <StyledStatusIcon status={status} icon={closeCircle} />;
    }
    return null;
  };

  if (updating) {
    return <StyledStatusSpinner name="lines-small" color="primary" />;
  } else {
    return (
      <StyledEventStatusInfo onClick={handleClick}>
        {renderIcon(status)}
        <StyledStatusText>{status}</StyledStatusText>
      </StyledEventStatusInfo>
    );
  }
};

export default EventStatusInfo;
