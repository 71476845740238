import { IonItem } from '@ionic/react';
import styled from 'styled-components';

export const StyledIonItem = styled(IonItem)`
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 8px;
  margin-top: 8px;
  border-radius: 10px;
`;

export const StyledTimeText = styled.span`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  color: #6e6e6e;
`;

export const StyledTitleText = styled.div`
  font-size: 18px;
  font-weight: 800;
  margin-top: 5px;
  margin-bottom: 5px;
`;
